export const cardsSliderItems = [
  {
    content: [
      {
        id: 0,
        title: "2014",
        text:
          "Início da parceria comercial com a Lecca para prospecção, negociação e implantação do Bank as a Service.",
      },
      {
        id: 1,
        title: "2015",
        text: "Início das Operações de Crédito Consignado Privado.",
      },
      {
        id: 2,
        title: "2016",
        text:
          "Consolidação como maior estruturador de Bank as a Service do mercado.",
      },
      {
        id: 3,
        title: "2017",
        text:
          "Expansão da operação de Consignado para todo território nacional.",
      },
      {
        id: 4,
        title: "2018",
        text: "Lançamento do 1º APP de Consignado Privado do Brasil.",
      },
      {
        id: 5,
        title: "2019",
        text:
          "Obtenção da Licença para Zipdin SCD, um grande marco para nossa independência operacional.",
      },
      {
        id: 6,
        title: "2020",
        text: "Lançamento da plataforma para operações de Crédito PJ as a Service.",
      },
    ],
  },
];
