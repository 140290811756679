import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.main`
  overflow: hidden;

  .About__Banner {
    height: auto;

    ${mediaQuery.desktop`
      height: 1156px;
    `}
  }

  .About__jumbotron {
    padding: 36px;
    color: ${({ theme }) => theme.font.primary};
    margin-top: 194px;

    ${mediaQuery.desktop`
      padding: 80px;
      margin-top: inherit;
    `}
  }

  .Banner__paragraf {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.4px;

    ${mediaQuery.desktop`
      font-size: 20px;
    `}
  }

  .bold {
    font-weight: 600;
    color: ${({ theme }) => theme.font.second};
  }

  .About__our-history {
    padding: 0 0 80px;
    background-color: ${({ theme }) => theme.bg.fifth};

    ${mediaQuery.desktop`
       padding: 0 0 150px;
    `}

    svg .b {
      fill: ${({ theme }) => theme.font.primary};
    }

    .our-history__title {
      margin-top: 0px !important;
      background-color: ${({ theme }) => theme.bg.fifth};
    }

    .our-history__slider-item {
      h3 {
        color: ${({ theme }) => theme.font.third};
      }
      p {
        max-width: 270px;
        padding: 0;
        color: ${({ theme }) => theme.font.primary};
        text-align: left;
        margin: 0;
        font-size: 12px;

        ${mediaQuery.desktop`
          font-size: inherit;
        `}
      }
    }
  }
  .container.About__next-page {
    padding: 150px 0;
   
    justify-content: center;
    display: none;

    ${mediaQuery.desktop`
       display: flex;
    `}

    button {
      font-weight: 600;
      text-transform: uppercase;
      min-height: 53px;
    }
  }
`;
