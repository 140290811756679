import React from "react";
import { navigate } from "gatsby";

import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import TitleSection from "../../components/TitleSection";
import Slider from "../../components/Slider";
import Button from "../../components/Button";

import { useWindowSize } from "../../hooks/resizeWindow";

import { Container } from "./styles";

import { cardsSliderItems } from "../../content/pages/About";
import bgAbout from "../../assets/images/bg-about.jpg";
import bgAboutMobile from "../../assets/images/bg_about-mobile.jpg";

const About: React.FC = () => {
  const size = useWindowSize();

  function handleNextPage() {
    navigate("/contato");
  }

  React.useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Container>
        <Banner
          containerClass="About__Banner"
          classJumbotron="col-md-9 About__jumbotron"
          title="Quem somos"
          backgroundImg={size.width > 800 ? bgAbout : bgAboutMobile}
        >
          <div className="Banner__content">
            <p className="Banner__paragraf">
              A Zipdin é uma fintech que nasceu com o objetivo de <strong>democratizar o acesso ao crédito.</strong>
            </p>
            <br />
            <p className="Banner__paragraf">
              Começamos com Bank as a Service e não paramos mais. Estamos em constante evolução, expandindo e criando novos serviços e experiências mais positivas para que empresas possam realizar seus projetos e pessoas os seus sonhos.
            </p>
            <br />
            <p className="Banner__paragraf">
              Sempre atentos às necessidades dos clientes e ligados no futuro, usamos <strong>tecnologia de ponta</strong> para desenvolver <strong>soluções de crédito descomplicadas, eficientes e customizadas.</strong> </p>
            <br />
            <p className="Banner__paragraf">
              Oferecemos um <strong>portfólio de serviços de crédito</strong> completo  e modularizado para ajudar quem quer montar a própria fintech.
            </p>
            <br />
            <p className="Banner__paragraf">
              Por isso, somos a sua plataforma de crédito definitiva.
            </p>
            <br />
            <p className="Banner__paragraf bold">
              Somos a Zipdin. Dar crédito é a maneira de dizer que acreditamos no seu futuro.
            </p>
          </div>
        </Banner>
        <section className="About__our-history">
          <TitleSection
            className="our-history__title"
            title="Nossa História"
            text="Começamos com Bank as a Service e não paramos mais. Conheça melhor a nossa trajetória:"
          />
          <Slider
            classContainer="our-history__slider"
            classItem="our-history__slider-item"
            arrowColor={`${(theme) => theme.font.primary}`}
            items={cardsSliderItems}
          />
        </section>

        <section className="container About__next-page">
          <Button onClick={handleNextPage}>Fale conosco</Button>
        </section>
      </Container>
    </Layout>
  );
};

export default About;
